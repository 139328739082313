export default [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login"),
    meta: {
      requireAuth: false,
      redirectHomeIfLogged: true,
    },
  },
  {
    path: "/modules",
    name: "Modules",
    component: () => import("@/views/Modules"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
      title: "Módulos",
    },
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: () => import("@/views/UserProfile"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
      title: "Informações do usuário",
    },
  },
  {
    name: "ReportGallery",
    path: "/report-gallery",
    component: () => import("@/views/ReportGallery"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Report",
    path: "/report",
    component: () => import("@/views/Report"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
];
