export default function getModulePort(module) {
  if (module === "portal") {
    return 8080;
  } else if (module === "security") {
    return 8081;
  } else if (module === "masterdata") {
    return 8082;
  } else if (module === "sales") {
    return 8083;
  } else if (module === "purchase") {
    return 8084;
  } else if (module === "production") {
    return 8085;
  } else if (module === "opex") {
    return 8086;
  } else if (module === "capex") {
    return 8087;
  } else if (module === "pl") {
    return 8088;
  } else if (module === "dfc") {
    return 8089;
  }
}
