import getApiLocalPort from "./getApiLocalPort";

export default function setApiRoutes(Vue, system, env) {
  const domain = ".znaptech.com/";

  const setProtocol = (environment) => {
    return environment === "local" ? "http://" : "https://";
  };

  const setApi = (environment) => {
    let apiName = "";

    if (environment === "homol") {
      apiName = "homol.";
    }

    if (environment === "dev") {
      apiName = "dev.";
    }

    return `${system.toLowerCase()}.api.${apiName}`;
  };

  const setRoute = (path) => {
    const protocol = setProtocol(env);

    if (env === "local") {
      const apiLocalPort = getApiLocalPort(path);
      return `${protocol}localhost:${apiLocalPort}/`;
    }

    const api = setApi(env);
    return `${protocol}${api}${path}${domain}`;
  };

  Vue.prototype.$ipUser = setRoute("user");
  Vue.prototype.$ipClient = setRoute("client");
  Vue.prototype.$ipPlPlanning = setRoute("pl.planning");
  Vue.prototype.$ipDynamicReport = setRoute("dynamic.report");
}
