export default {
  light: "#ffffff",
  black: "#222222",
  neutral: "#34313F",

  primary: "#E71B7B",
  secondary: "#611F96",

  error: "#FF5252",
  info: "#38afff",
  success: "#4CAF50",
  warning: "#ffc830",
};
