<template>
  <v-dialog v-model="showModal" width="500" persistent>
    <v-card>
      <v-card-title
        class="light--text"
        :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
      >
        <span> Erro no login Azure </span>

        <v-spacer></v-spacer>

        <v-icon min-width="48px" text dark @click="close"> mdi-close </v-icon>
      </v-card-title>

      <v-divider class="mb-3 mx-3"></v-divider>

      <v-card-text>
        <section class="d-flex">
          <div style="white-space: pre-wrap" class="text-start">
            <p>
              Sua autenticação no AD da Azure foi concluída com sucesso, porem
              seu cadastro está incompleto.
              <strong>
                O campo e-mail esta ausente ou incorreto, este campo deve
                corresponder ao mesmo e-mail registrado na ZNAP.
              </strong>
            </p>

            <p>
              Entre em contato com o administrador do sistema para que seu
              e-mail seja adicionado ao cadastro no Azure.
            </p>

            <strong>
              Essa aba permanecerá “bloqueada” para novos acessos pelo Azure,
              caso deseje acessar com outra conta, utilize uma aba anônima ou
              reinicie o navegador.
            </strong>
          </div>
        </section>
      </v-card-text>

      <v-card-actions class="px-6 pb-6">
        <v-spacer></v-spacer>

        <v-btn type="submit" color="primary" @click="submit"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      errorMessage: "",
      errorCode: "",
    };
  },
  methods: {
    show(errorData) {
      this.showModal = true;
      this.errorMessage = errorData.message;
      this.errorCode = errorData.code;
    },
    close() {
      this.$emit("hide");
    },
    hide() {
      this.showModal = false;
      this.errorMessage = "";
      this.errorCode = "";
    },
    submit() {
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
