const getDefaultState = () => {
  return {
    issueDialog: false,
    lastError: null,
    lastRequest: null,
  };
};

const state = getDefaultState();

const getters = {
  getIssueDialog: (state) => state.issueDialog,
  getLastError: (state) => state.lastError,
  getLastRequest: (state) => state.lastRequest,
};

const mutations = {
  setIssueDialog(state, payload) {
    state.issueDialog = payload;
  },

  setLastError(state, payload) {
    state.lastError = payload;
  },

  setLastRequest(state, payload) {
    state.lastRequest = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
